import { UserProducts } from '../../interfaces/products/takeBack'

interface ReducerState {
  loading: boolean
  response?: UserProducts
  takeBackStock?: any
  withdrawalInfo?: any
  error?: any
}
export const initialTakeBackState: ReducerState = { loading: false }

const takeBackReducer = (
  state = initialTakeBackState,
  action
): ReducerState => {
  switch (action.type) {
    case 'POST_TAKE_BACK_INFO_REQUEST':
    case 'GET_TAKE_BACK_REFERENCE_REQUEST':
    case 'GET_TAKE_BACK_STOCK_REQUEST':
    case 'GET_USER_TAKE_BACK_PRODUCTS_REQUEST':
    case 'GET_RIGHT_OF_WITHDRAWAL_INFO_REQUEST':
      return { ...state, loading: true }
    case 'POST_TAKE_BACK_INFO_SUCCESS':
    case 'GET_TAKE_BACK_REFERENCE_SUCCESS':
    case 'GET_USER_TAKE_BACK_PRODUCTS_SUCCESS':
      return { ...state, loading: false, response: action.response }
    case 'GET_TAKE_BACK_STOCK_SUCCESS':
      return { ...state, loading: false, takeBackStock: action.response }
    case 'GET_RIGHT_OF_WITHDRAWAL_INFO_SUCCESS':
      return { ...state, loading: false, withdrawalInfo: action.response }
    case 'POST_TAKE_BACK_INFO_FAILURE':
    case 'GET_TAKE_BACK_REFERENCE_FAILURE':
    case 'GET_TAKE_BACK_STOCK_FAILURE':
    case 'GET_USER_TAKE_BACK_PRODUCTS_FAILURE':
    case 'GET_RIGHT_OF_WITHDRAWAL_INFO_FAILURE':
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
export default takeBackReducer
