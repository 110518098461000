import axiosWrapper from '../helpers/api'
import { ERequestUrl, EMethods } from '../helpers/requests'

export const getTaxons = () =>
  axiosWrapper({ method: EMethods.get, url: ERequestUrl.taxons })

export const getTaxonsAttributes = (taxonSlug) =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.taxonAttributes,
    uid: taxonSlug,
  })

export const getTaxonsSizes = (taxonCode) =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.taxonSizes,
    uid: taxonCode + '/sizes',
  })
