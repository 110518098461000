import { Product, ProductGroupLinks } from '../../interfaces/products/shop'
import { ACTIONS } from '../actions/products'

interface ReducerState {
  loading: boolean
  totalItems?: any
  latestProducts?: any
  loadingCumulative?: boolean
  currentSlug?: any
  currentSearch?: string | string[]
  isCumulative?: boolean
  error?: any
  filteredProducts?: Product[]
  filters?: any[]
  items: {
    page?: number
    limit?: number
    pages?: number
    total?: number
    _links?: ProductGroupLinks
    items: Product[]
  }
  stock?: any[]
}

export const initialProductsState: ReducerState = {
  loading: true,
  items: {
    items: [],
  },
  totalItems: [],
  filters: [],
  latestProducts: [],
  loadingCumulative: false,
  currentSlug: '',
  currentSearch: '',
}

const productsReducer = (
  state = initialProductsState,
  action
): ReducerState => {
  switch (action.type) {
    case ACTIONS.GET_PRODUCTS_REQUEST:
    case ACTIONS.GET_LATEST_PRODUCTS_REQUEST:
      return { ...state, loading: true }
    case ACTIONS.GET_CUMULATIVE_PRODUCTS_REQUEST:
      return { ...state, loading: true }
    case ACTIONS.SET_CURRENT_SHOP_SLUG:
      return { ...state, currentSlug: action.slug }
    case ACTIONS.SET_CURRENT_SEARCH:
      return { ...state, currentSearch: action.search }
    case ACTIONS.EMPTY_PRODUCTS:
      return { ...initialProductsState }
    case ACTIONS.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        isCumulative: false,
        items: action.products,
      }
    case ACTIONS.GET_CUMULATIVE_PRODUCTS_SUCCESS: {
      const cumulatedStock = [
        ...(state.items.items || []),
        ...(action.products.items || []),
      ]
      const uniqueProducts = cumulatedStock.filter(
        (obj, index) =>
          cumulatedStock.findIndex((item) => item.code === obj.code) === index
      )
      return {
        ...state,
        loading: false,
        isCumulative: true,
        items: {
          limit: action.products.limit,
          page: action.products.page,
          pages: action.products.pages,
          total: action.products.total,
          items: uniqueProducts,
        },
      }
    }
    case ACTIONS.GET_LATEST_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        latestProducts: action.product_latest?.items,
      }
    case ACTIONS.GET_PRODUCTS_FAILURE:
    case ACTIONS.GET_CUMULATIVE_PRODUCTS_FAILURE:
    case ACTIONS.GET_LATEST_PRODUCTS_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}

export default productsReducer
