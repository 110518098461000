import { AxiosRequestHeaders } from 'axios'

export enum EMethods {
  post = 'post',
  get = 'get',
  delete = 'delete',
  put = 'put',
  patch = 'patch',
}

export enum ERequestUrl {
  root = 'shop-api/',
  rootV2 = 'api/v2',
  products = 'shop-api/products',
  register = 'shop-api/register',
  login = 'shop-api/login',
  productsByTaxonSlug = 'shop-api/taxon-products/by-slug/',
  taxons = 'shop-api/taxons/',
  userInfo = 'shop-api/me',
  verifyUser = 'shop-api/verify-account',
  address = 'shop-api/address-book/',
  return = 'shop-api/shipments/return',
  userOrders = 'shop-api/orders',
  createCart = 'shop-api/carts',
  promotions = 'shop-api/promotions',
  forgetPassword = 'shop-api/request-password-reset',
  resetPassword = 'shop-api/password-reset',
  checkout = 'shop-api/checkout/',
  pointsRelais = 'https://ws.chronopost.fr/recherchebt-ws-cxf/PointRelaisServiceWS/rechercheBtParCodeproduitEtCodepostalEtDate?codePostal=',
  stripe = 'shop-api/payment',
  taxonPack = 'shop-api/taxon-packs',
  packs = 'shop-api/packs/taxon/',
  productsLatest = 'shop-api/product-latest/',
  reviews = 'shop-api/reviews',
  countries = 'api/v2/shop/countries?enabled=true',
  warehouseOrder = 'shop-api/warehouse/orders',
  contractCSUB = 'api/v2/shop/classic-subscription-contracts',
  contractSUB = 'api/v2/shop/pay-as-you-go-subscription-contracts',
  apiGouvFr = 'https://calendrier.api.gouv.fr/jours-feries/metropole',
  cards = 'shop-api/cards',
  token = 'shop-api/users/token',
  takeBackRef = 'shop-api/product/sticker-reference/',
  payment = 'shop-api/payment/',
  paths = 'shop-api/products-packs-slugs',
  taxonAttributes = 'shop-api/taxon-attributes/',
  taxonSizes = 'shop-api/taxon/',
  balance = 'shop-api/payment-method/balance',
  invoice = 'shop-api/orders/',
  postTakeBackInfo = 'shop-api/second-hand/take-back',
}

export enum RMSRequestUrl {
  stock = 'stock',
  pickingPlace = 'parcelshops',
  resetStock = 'fixtures/reset?accessToken=WrNiWhwhiyx2i8sl74mb1wLMRTxTtjG9',
  productAvailability = 'stock/variant-unavailability',
  takeBackStock = 'stock/second-hand',
  stockAlert = 'stock/alert',
  userTakeBackProducts = 'second-hand/requests',
  rightOfWithdrawal = 'orders',
  productsSearch = 'products/search',
}

export type TAxiosWrapper = {
  method: EMethods
  hasLocale?: boolean
  url: ERequestUrl | RMSRequestUrl
  headers?: AxiosRequestHeaders
  data?: object
  uid?: string
  name?: string | undefined
  params?: object
  customLocale?: string
  isV2?: boolean
}
