interface ReducerState {
  loading: boolean
  cardUpdated?: boolean
  userVerified?: boolean
  email?: any
  user?: any
  orders?: any
  order?: any
  cards?: any
  balance?: any
  ghostUser?: any
  error?: any
}
export const initialProductsState: ReducerState = { loading: false }

const userReducer = (state = initialProductsState, action): ReducerState => {
  switch (action.type) {
    case 'SAVE_USER_REQUEST':
    case 'REGISTER_USER_REQUEST':
    case 'LOGIN_USER_REQUEST':
    case 'GET_USER_ORDERS_REQUEST':
    case 'GET_USER_ORDER_BY_TOKEN_REQUEST':
    case 'PUT_USER_REQUEST':
    case 'PUT_USER_PASSWORD_REQUEST':
    case 'FORGET_PASSWORD_REQUEST':
    case 'GET_USER_REQUEST':
    case 'VERIFY_USER_REQUEST':
    case 'RESET_PASSWORD_REQUEST':
    case 'PUT_USER_ORDER_REQUEST':
      return { ...state, loading: true }
    case 'GET_USER_CARDS_REQUEST':
    case 'POST_USER_CARD_REQUEST':
      return { ...state, loading: true, cardUpdated: false }
    case 'VERIFY_USER_SUCCESS':
      return { ...state, loading: false, userVerified: true }
    case 'SAVE_USER_SUCCESS':
      return { ...state, loading: false, email: action.email }
    case 'REGISTER_USER_SUCCESS':
      return { ...state, loading: false, user: action.token }
    case 'LOGIN_USER_SUCCESS':
    case 'PUT_USER_SUCCESS':
    case 'GET_USER_SUCCESS':
      return { ...state, loading: false, user: action.user }
    case 'GET_USER_ORDERS_SUCCESS':
      return { ...state, loading: false, orders: action.orders }
    case 'GET_USER_ORDER_BY_TOKEN_SUCCESS':
      return { ...state, loading: false, order: action.orders }
    case 'GET_USER_CARDS_SUCCESS':
      return {
        ...state,
        loading: false,
        cardUpdated: false,
        cards: action.cards,
      }
    case 'GET_USER_BALANCE_SUCCESS':
      return {
        ...state,
        balance: action.balance,
      }
    case 'POST_USER_CARD_SUCCESS':
      return {
        ...state,
        loading: false,
        cardUpdated: true,
        cards: action.cards,
      }
    case 'SAVE_GHOST_USER':
      return { ...state, loading: false, ghostUser: action.ghostUserData }
    case 'PUT_USER_PASSWORD_SUCCESS':
    case 'RESET_PASSWORD_SUCCESS':
    case 'FORGET_PASSWORD_SUCCESS':
    case 'PUT_USER_ORDER_SUCCESS':
    case 'LOGIN_USER_FAILURE':
    case 'REGISTER_USER_FAILURE':
    case 'PUT_USER_FAILURE':
    case 'GET_USER_ORDERS_FAILURE':
    case 'GET_USER_ORDER_BY_TOKEN_FAILURE':
    case 'FORGET_PASSWORD_FAILURE':
    case 'GET_USER_FAILURE':
    case 'RESET_PASSWORD_FAILURE':
    case 'PUT_USER_ORDER_FAILURE':
    case 'GET_USER_CARDS_FAILURE':
    case 'POST_USER_CARDS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.err,
      }
    case 'VERIFY_USER_FAILURE':
      return {
        ...state,
        loading: false,
        userVerified: false,
        error: action.err,
      }
    case 'DELETE_USER_SUCCESS':
    case 'DELETE_GHOST_USER':
      return { loading: false }
    default:
      return state
  }
}

export default userReducer
