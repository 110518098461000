import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

export const i18nextInit = async (router, i18nResources) => {
  if (!i18nResources) {
    return
  }

  const { translations, namespaces } = i18nResources
  if (!i18next.isInitialized) {
    i18next.use(initReactI18next).init({
      lng: router.locale,

      supportedLngs: router.locales,

      fallbackLng: router.defaultLocale,

      ns: namespaces,

      react: {
        useSuspense: false,
      },
    })
  }

  i18next.setDefaultNamespace(namespaces[0])

  if (i18next.language !== router.locale) {
    i18next.changeLanguage(router.locale)
  }

  namespaces.forEach((ns) => {
    if (!i18next.hasResourceBundle(router.locale, ns)) {
      i18next.addResourceBundle(router.locale, ns, translations[ns])
    }
  })
}

export async function getTranslations(locale, namespaces) {
  const translations = {}
  const shortLocale = locale.substring(0, 2)

  for (const ns of namespaces) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const data = require(`./public/static/locales/${shortLocale}/${ns}.json`)

    translations[ns] = data
  }

  return { translations, namespaces }
}
